<template>
	<div class="form">
		<div class="form_left">
			<el-button type="primary" :class="showIndex==1?'active':'btn'" @click="tab(1)">{{$t('QY_msg.JBXX')}}</el-button>
			<el-button type="primary"  :class="showIndex==2?'active':'btn'" @click="tab(2)">{{$t('my_msg.QZXX')}}</el-button>
		</div>
		
		<!-- 基本信息 -->
		<div class="form_center" v-if="showIndex==1" v-loading="loading"  element-loading-background="rgba(0, 0, 0, 0.8)">
			<div class="center_top">
				<div class="top_dian">
					
				</div>
				<div class="top_text">
					<!-- 基本信息 -->
					{{$t('QY_msg.JBXX')}}
				</div>
			</div>
			<!-- <img src="../../assets/img/form.png" class="login_logo" alt=""> -->
			<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="160px" class="demo-ruleForm img_form">
				<el-form-item :label="$t('EMAILPHONE')" prop="account">					
					<div style="display: flex;align-items: center;">
						<!-- <el-input v-model="ruleForm.account"></el-input> -->
						<div>{{ruleForm.account}}</div>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				
				<el-form-item :label="$t('my_msg.CSRQ')" prop="birthday">
					<el-date-picker
					      v-model="ruleForm.birthday"
					      type="date"
						  value-format="yyyy-MM-dd"
					      :placeholder="$t('QXZ')">
					    </el-date-picker>
				</el-form-item>
				<el-form-item :label="$t('my_msg.NL')" prop="age">
					<el-input v-model="ruleForm.age" readonly></el-input>
					
				</el-form-item>
				<el-form-item :label="$t('AGEOPEN')" prop="open_age_method">
					<el-radio-group v-model="ruleForm.open_age_method">
						<el-radio :label="item.data_code/1" v-for="item in openAge" :key="item.data_code">{{item.data_value}}</el-radio>
					</el-radio-group>
				</el-form-item>
				<!-- <el-form-item :label="$t('my_msg.ZYBS')" prop="profession">
					<el-input v-model="ruleForm.profession" placeholder=""></el-input>
				</el-form-item> -->
				<el-form-item :label="$t('my_msg.YWNL')" prop="skills">
					<el-tag	v-for="(tag,index) in ruleForm.skills" :key="tag" @close="ruleForm.skills.splice(index,1)" closable effect="plain" style="margin-right: 10px;">{{tag}}</el-tag>
					<!-- <el-cascader @change="selectSkill" v-model="ruleForm.skills" :options="skillList" :show-all-levels="false" :props="{multiple:false,value:'label'}" style="width: 300px;"></el-cascader> -->
					<!-- <el-dropdown  @command="selectSkill" :hide-on-click="false" >
					  <el-button type="primary" size="mini">
					    {{$t('QXZ')}}<i class="el-icon-arrow-down el-icon--right"></i>
					  </el-button>
					  <el-dropdown-menu slot="dropdown">
						<template v-for="(item,index) in skillList" >
							<el-dropdown-item disabled :divided="index>0">{{item.label}}</el-dropdown-item>
							<el-dropdown-item :command="sitem.label" v-for="(sitem,sindex) in item.children" :key="sitem.value">{{sitem.label}}</el-dropdown-item>
						</template>
					  </el-dropdown-menu>
					</el-dropdown> -->
					<el-button type="primary" size="mini" @click="openSkill">
					  {{$t('QXZ')}}<i class="el-icon-arrow-down el-icon--right"></i>
					</el-button>
					<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
				</el-form-item>
				<el-form-item :label="$t('my_msg.XB')" prop="gender">
					<el-radio-group v-model="ruleForm.gender">
						<el-radio label="man"><!-- 男性 -->{{$t('my_msg.MAN')}}</el-radio>
						<el-radio label="female"><!-- 女性 -->{{$t('my_msg.WOMAN')}}</el-radio>
						<!-- <el-radio label="none">{{$t('my_msg.BXHD')}}</el-radio> -->
					</el-radio-group>
				</el-form-item>
				<el-form-item :label="$t('SEXOPEN')"  >
					<el-radio-group v-model="ruleForm.open_gender_method">
						<el-radio :label="item.data_code/1" v-for="item in openGender" :key="item.data_code">{{item.data_value}}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item :label="'yours'+$t('my_msg.NC')" prop="nickname">
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.nickname"></el-input>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				<el-form-item :label="$t('my_msg.ZSXM')" prop="realname">
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.realname" :placeholder="$t('NAMETIP')"></el-input>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				<el-form-item :label="$t('my_msg.ENXM')" prop="en_name">
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.en_name" :placeholder="$t('QSR')"></el-input>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				<el-form-item :label="$t('my_msg.LXFS')" prop="contact_info">
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.contact_info" ></el-input>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				<el-form-item :label="$t('my_msg.YZBM')" prop="postcode">
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.postcode" type="number"></el-input>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				<el-form-item :label="$t('my_msg.DDFX')" prop="cityname">
					<div style="display: flex;align-items: center;">
						<!-- <el-input v-model="ruleForm.name"></el-input> -->
						<!-- <template v-if="lang=='zh'">
						<el-cascader @change="selectCity" :options="citydata" v-model="ruleForm.cityname" :props="{label:'name',value:'name'}" :placeholder="$t('QXZ')"></el-cascader>
						</template>
						<template v-else> -->
						<el-cascader @change="selectCity" :options="citydata" v-model="ruleForm.cityname" :props="{label:'label',value:'label'}" :placeholder="$t('QXZ')"></el-cascader>
						<!-- </template> -->
						<el-tag effect="plain" size="mini" style="margin-left: 10px;"><!-- 非公开 -->{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				<!-- <el-form-item :label="$t('my_msg.DWDZ')" prop="location">
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.location"></el-input>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;">{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item> -->
				<el-form-item :label="$t('my_msg.XXDD')" prop="address">
					<div style="display: flex;align-items: center;">
						<el-input v-model="ruleForm.address" :placeholder="$t('ADRTIP')"></el-input>
						<el-tag effect="plain" size="mini" style="margin-left: 10px;">
							
						{{$t('my_msg.FGK')}}</el-tag>
					</div>
				</el-form-item>
				
				
			</el-form>
		
			<div class="center_top" id="bcxx">
				<div class="top_dian">
					
				</div>
				<div class="top_text">
					<!-- 补充信息 -->{{$t('my_msg.BCXX')}}
				</div>
			</div>
			<el-form   class="demo-ruleForm img_form" label-width="160px">
				<el-form-item :label="$t('my_msg.TX')">
					
					<img v-if="ruleForm.avatar_url" :src="ruleForm.avatar_url" style="width: 148px;height: 148px;border: 1px solid #c0ccda;border-radius: 6px;margin:0 8px 8px 0;box-sizing: border-box;object-fit: cover;">
					<el-upload
						style="display: inline;"
					  class="avatar-uploader"
					  :action="$config.uploadUrl"
					  :show-file-list="false"
					  :on-success="upload_avatar"
					  list-type="picture-card"
					  accept=".jpg,.jpeg,.png,.gif,.webp,.bmp"
					  >
					   <i class="el-icon-plus"></i>
					 
					</el-upload>
					
				</el-form-item>
				<el-form-item :label="$t('my_msg.ZPJ')" >
					<imglist :datas="ruleForm.portfolio"></imglist>
					<el-upload style="display: inline;"
					  :action="$config.uploadUrl"
					  list-type="picture-card"
					   :show-file-list="false"
					   :on-success="upload_photo"
					    multiple
						accept=".jpg,.jpeg,.png,.gif,.webp,.bmp"
					>
					  <i class="el-icon-plus"></i>
					</el-upload>
				</el-form-item>
			</el-form>
			<div class="center_top">
				<div class="top_dian">
					
				</div>
				<div class="top_text">
					<!-- 外部链接 -->{{$t('my_msg.WBLJ')}}
				</div>
			</div>
			<el-form  class="demo-ruleForm img_form" label-width="160px">
				<el-form-item label="LinkedIn">
					<el-input v-model="ruleForm.linkedin_url"></el-input>
				</el-form-item>
				<el-form-item label="FacebooK">
					<el-input v-model="ruleForm.facebook_url"></el-input>
				</el-form-item>
				<el-form-item label="Twiter">
					<el-input v-model="ruleForm.twitter_url"></el-input>
				</el-form-item>
			</el-form>
			<div class="center_top" id="grjj">
				<div class="top_dian">
					
				</div>
				<div class="top_text">
					<!-- 个人简介 -->{{$t('my_msg.GRJJ')}}
				</div>
			</div>
			<el-form  class="demo-ruleForm img_form" label-width="160px">
				<el-form-item :label="$t('my_msg.GRJJ')">
					<el-input
					  type="textarea"
					  :rows="4" maxlength="1000" show-word-limit
					  :placeholder="$t('QSR')"
					  v-model="ruleForm.description">
					</el-input>
				</el-form-item>
			</el-form>
			
			<el-button type="primary" @click="submitForm('ruleForm')"><!-- 确认 -->{{$t('my_msg.QR')}}</el-button>
		</div>
		<!-- 求职信息 -->
		<div class="form_center" v-if="showIndex==2" v-loading="loading"  element-loading-background="rgba(0, 0, 0, 0.8)">
			<div class="center_top">
				<div class="top_dian">
					
				</div>
				<div class="top_text">
					<!-- 求职信息 -->{{$t('my_msg.QZXX')}}
				</div>
			</div>
			<!-- <img src="../../assets/img/form.png" class="login_logo" alt=""> -->
			<el-form   class="demo-ruleForm" label-width="160px" style="width: 770px; ">
				
				<el-form-item :label="$t('my_msg.RZSJ')">
					<el-date-picker
					      v-model="jobForm.job_time"
					      type="date"
						  value-format="yyyy-MM-dd"
					      :placeholder="$t('QXZ')">
					    </el-date-picker>
				</el-form-item>
				<el-form-item :label="$t('my_msg.XWXZ')">
					<div style="display: flex;align-items: center;">
						<el-input v-model="jobForm.hope_wage" type="number"></el-input>
						
						<el-select v-model="jobForm.salary_unit" :placeholder="$t('QXZ')" style="margin-left:10px;">
							<el-option v-for="item in salaryUnit" :key="item.data_code" :label="item.data_value"
								:value="item.data_value">
							</el-option>
						</el-select>
					</div>
					
				</el-form-item>
				<el-form-item label-width="0">
					
					<!-- <el-radio-group v-model="jobForm.job_time_method">
						<el-radio :label="item.data_code/1" v-for="item in jobTimeMethod" :key="item.data_code">{{item.data_value}}</el-radio>
						
					</el-radio-group> -->
					<label class="el-form-item__label" style="width: 160px;">
						<div>{{$t('HOPETIME')}}</div>
						<!-- <div>{{$t('MEITIAN')}}</div> -->
					</label>
					<div style="display: flex;align-items: center;">
						
						<el-input v-model="jobForm.job_time_val" type="number"></el-input>
						<div style="flex-shrink: 0;color: #606266;margin-left: 10px;">{{$t('my_msg.XS')}}</div>
					</div>
				</el-form-item>				
				<el-form-item :label="$t('my_msg.XWGW')" prop="skills">
					<el-tag	v-for="(tag,index) in jobForm.hope_post" :key="tag" @close="jobForm.hope_post.splice(index,1)" closable effect="plain" style="margin-right: 10px;">{{tag}}</el-tag>
					<!-- <el-cascader @change="selectHope"  :options="cateList"  :show-all-levels="false" :props="{multiple:false,value:'label'}" style="width: 80px;" placeholder="选择"></el-cascader> -->
					
					<el-dropdown   :hide-on-click="true" trigger="click" >
					  <el-button type="primary" size="mini">
					    {{$t('QXZ')}}<i class="el-icon-arrow-down el-icon--right"></i>
					  </el-button>
					  <el-dropdown-menu slot="dropdown" style="width: 300px;">
					  <el-tree :data="cateList" :accordion="true" @node-click="selectHope"></el-tree>
					  </el-dropdown-menu>
					 <!-- <el-dropdown-menu slot="dropdown">
						<template v-for="(item,index) in cateList">
							<el-dropdown-item disabled :divided="index>0">{{item.label}}</el-dropdown-item>
							<el-dropdown-item :command="sitem.label" v-for="(sitem,sindex) in item.children" :key="sitem.value">{{sitem.label}}</el-dropdown-item>
						</template>
						
					  </el-dropdown-menu> -->
					</el-dropdown>
				
					<el-tag effect="plain" size="mini" style="margin-left: 10px;">{{$t('my_msg.GK')}}</el-tag>
				</el-form-item>
				
				<el-form-item :label="$t('my_msg.BGFS')" prop="gender">
					<el-checkbox-group v-model="jobForm.job_type">
						<el-checkbox :label="item.data_value" v-for="item in jobType" :key="item.data_code"></el-checkbox>
						
					</el-checkbox-group>
				</el-form-item>
				<!-- <el-form-item :label="$t('my_msg.XLDJ')" prop="name" >
					<el-select v-model="jobForm.edu_level" :placeholder="$t('QXZ')">
						<el-option v-for="item in eduLevel" :key="item.data_code" :label="item.data_value"
							:value="item.data_value">
						</el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item :label="$t('my_msg.SKRXM')" prop="nickname">
					<div style="display: flex;align-items: center;">
						<el-input v-model="jobForm.payee_name" :placeholder="$t('QSR')"></el-input>
					</div>
				</el-form-item>
				<el-form-item :label="$t('my_msg.SKRDZ')" prop="realname">
					<div style="display: flex;align-items: center;">
						<el-input v-model="jobForm.payee_address" :placeholder="$t('QSR')"></el-input>
					</div>
				</el-form-item>
				<el-form-item :label="$t('my_msg.SKZH')" prop="contact_info">
					<div style="display: flex;align-items: center;">
						<el-input v-model="jobForm.payee_account" :placeholder="$t('QSR')"></el-input>
					</div>
				</el-form-item>
				<el-form-item :label="$t('my_msg.SKYH')" prop="postcode">
					<div style="display: flex;align-items: center;">
						<el-input v-model="jobForm.payee_bank" :placeholder="$t('QSR')"></el-input>
					</div>
				</el-form-item>
				<el-form-item :label="$t('my_msg.SKHB')" prop="location">
					<div style="display: flex;align-items: center;">
						<el-input v-model="jobForm.payee_currency" :placeholder="$t('QSR')"></el-input>
					</div>
				</el-form-item>
				<el-form-item label="SwiftCode" prop="address">
					<div style="display: flex;align-items: center;">
						<el-input v-model="jobForm.swift_code" placeholder=""></el-input>
					</div>
				</el-form-item>
				<template v-for="(item,index) in jobForm.ext_fields">
					<el-form-item >
						<div style="display: flex;align-items: center;">							
							<el-input v-model="item.name" :placeholder="$t('NAME')" style="width: 200px;margin-right: 10px;"></el-input>
							<el-input v-model="item.value" :placeholder="$t('VAL')"></el-input>
							<i class="el-icon-circle-close" style="color:#f00;font-size: 18px;margin-left: 10px;" @click="jobForm.ext_fields.splice(index,1)"></i>
						</div>
					</el-form-item>
				</template>
				<el-form-item>
					<el-button type="primary" @click="add_ext"><!-- 添加自定义字段 -->{{$t('my_msg.TJZDYZD')}}</el-button>
				</el-form-item>
			</el-form>
		
			<div class="center_top" id="xxjl">
				<div class="top_dian">
					
				</div>
				
				<div class="top_text" >
					<!-- 学习经历 -->
					{{$t('my_msg.XXJL')}}
				</div>
				<el-button type="primary" class="top_btn" @click="add_study_exp"><!-- 添加学习经历 -->{{$t('my_msg.TJXXJL')}}</el-button>
			</div>
			<el-form  :inline="true" :model="jobForm"  ref="studyForm" label-width="160px" class="demo-ruleForm">
				<div class="formpart" v-for="(item,index) in jobForm.study_exp" :key="index">
					<el-button size="mini" type="primary" class="delbtn" v-if="jobForm.study_exp.length>1" @click="jobForm.study_exp.splice(index,1)">{{$t('activity.SC')}}</el-button>
					<el-form-item :label="$t('my_msg.XXMC')" prop="name">
						<el-input v-model="item.name" placeholder=""></el-input>
					</el-form-item>
					<el-form-item :label="$t('my_msg.SXZY')">
						<el-input v-model="item.major" placeholder=""></el-input>
					</el-form-item>
					
					<el-form-item :label="$t('my_msg.XLDJ')">
						<div style="display: flex;align-items: center;">
							<el-input v-model="item.edu_level" placeholder=""></el-input>
						</div>
					</el-form-item>
					<el-form-item :label="$t('my_msg.ZXSJ')">
						<el-date-picker v-model="item.start_time" value-format="yyyy-MM-dd" class="picker_input" :placeholder="$t('QXZ')" style="width: 187px;"></el-date-picker>
						<el-date-picker v-model="item.end_time" value-format="yyyy-MM-dd" class="picker_input" :placeholder="$t('QXZ')" style="width: 187px;margin-left: 10px;"></el-date-picker>
					</el-form-item>
					<el-form-item :label="$t('my_msg.ZXJL')">
						<el-input type="textarea" class="textarea" v-model="item.experience" :rows="3" placeholder=""></el-input>
					</el-form-item>
				</div>
			</el-form>
			<div class="center_top" id="gzjl">
				<div class="top_dian"></div>
				<div class="top_text">
					<!-- 工作经历 -->
					{{$t('my_msg.GZJL')}}
				</div>
				<el-button type="primary" class="top_btn" @click="add_job_exp"><!-- 添加工作经历 -->{{$t('my_msg.TJGZJL')}}</el-button>
			</div>
			<el-form :inline="true" :model="jobForm" ref="jobForm" label-width="160px"  class="demo-ruleForm">
				<div class="formpart" v-for="(item,index) in jobForm.job_exp" :key="index">
					<el-button size="mini" type="primary" class="delbtn" v-if="jobForm.job_exp.length>1" @click="jobForm.job_exp.splice(index,1)">{{$t('activity.SC')}}</el-button>
					<el-form-item :label="$t('task.GSMC')">
						<el-input v-model="item.name" placeholder=""></el-input>
					</el-form-item>
					<el-form-item :label="$t('my_msg.SSZW')">
						<el-input v-model="item.position" placeholder=""></el-input>
					</el-form-item>
					
					<el-form-item :label="$t('my_msg.ZWXC')">
						<div style="display: flex;align-items: center;">
							<el-input v-model="item.salary" placeholder=""></el-input>
						</div>
					</el-form-item>
					<el-form-item :label="$t('my_msg.ZZSJ')">
						<el-date-picker v-model="item.start_time" value-format="yyyy-MM-dd" class="picker_input" :placeholder="$t('QXZ')" style="width: 187px;"></el-date-picker>
						<el-date-picker v-model="item.end_time" value-format="yyyy-MM-dd" class="picker_input" :placeholder="$t('QXZ')" style="width: 187px;margin-left: 10px;"></el-date-picker>
					</el-form-item>
					<el-form-item :label="$t('my_msg.GZNR')">
						<el-input type="textarea" class="textarea" v-model="item.content" :rows="3" placeholder=""></el-input>
					</el-form-item>
					<el-form-item :label="$t('my_msg.GZCJ')">
						<el-input type="textarea" class="textarea" v-model="item.performance" :rows="3" placeholder=""></el-input>
					</el-form-item>
				</div>
			</el-form>
			
			<div class="center_top">
				<div class="top_dian">
					
				</div>
				<div class="top_text">
					<!-- 项目经历 -->{{$t('my_msg.XMJL')}}
				</div>
				<el-button type="primary" class="top_btn" @click="add_project_exp"><!-- 添加项目经历 -->{{$t('TJXMJL')}}</el-button>
			</div>
			<el-form  :inline="true" :model="jobForm" ref="projectForm" label-width="160px" class="demo-ruleForm">
				<div class="formpart" v-for="(item,index) in jobForm.project_exp" :key="index">
					<el-button size="mini" type="primary" class="delbtn" v-if="jobForm.project_exp.length>1" @click="jobForm.project_exp.splice(index,1)">{{$t('activity.SC')}}</el-button>
				<el-form-item :label="$t('my_msg.XMMC')">
					<el-input v-model="item.name" placeholder=""></el-input>
				</el-form-item>
				<el-form-item :label="$t('my_msg.XMSS')">
					<el-input v-model="item.belong" placeholder=""></el-input>
				</el-form-item>
				
				<!-- <el-form-item :label="$t('my_msg.ZWXC')">
					<div style="display: flex;align-items: center;">
						<el-input v-model="item.salary" placeholder=""></el-input>
					</div>
				</el-form-item> -->
				<el-form-item :label="$t('my_msg.XMSJ')">
					<el-date-picker v-model="item.start_time" value-format="yyyy-MM-dd" class="picker_input" :placeholder="$t('QXZ')" style="width: 187px;"></el-date-picker>
					<el-date-picker v-model="item.end_time" value-format="yyyy-MM-dd" class="picker_input" :placeholder="$t('QXZ')" style="width: 187px;margin-left: 10px;"></el-date-picker>
				</el-form-item>
				<el-form-item :label="$t('my_msg.FZSX')">
					<el-input type="textarea" class="textarea" v-model="item.content" :rows="3" placeholder=""></el-input>
				</el-form-item>
				<el-form-item :label="$t('my_msg.XMCG')">
					<el-input type="textarea" class="textarea" v-model="item.performance" :rows="3" placeholder=""></el-input>
				</el-form-item>
				</div>
			</el-form>
			
			
			<el-button type="primary" @click="saveJob"><!-- 保存信息 -->{{$t('my_msg.QR')}}</el-button>
		</div>
		<skills ref="skills"></skills>
	</div>
</template>

<script>
	import skills from '@/components/skills.vue';
	import imglist from '@/components/imglist.vue';
	export default {
		components: {
			imglist,
			skills
		},
		data() {
			return {
				loading:false,
				selected:'',
				showIndex:1,				
				
				citydata:{},//省市区数据				
				skillList:[],//业务技能数据
				cateList:[],//岗位数据
				salaryUnit:[],//薪资单位数据
				jobTimeMethod:[],//出勤时间数据
				jobType:[],//办公方式数据
				eduLevel:[],//学历数据
				openAge:[],
				openGender:[],
				ruleForm: {
					account: '',
					address: '',
					age: '',
					area: '',
					avatar_url: '',
					birthday: '',
					city: '',
					contact_info: '',
					//country: '',
					description: '',
					facebook_url: '',
					twitter_url: '',
					gender: '',
					linkedin_url: '',
					location: '',
					nickname: '',
					
					open_age_method: 0,
					open_gender_method: 0,
					open_name_method: 0,
					portfolio: '',
					postcode: '',
					profession: '',
					province: '',
					realname: '',
					en_name:'',
					skills: [],
					user_id: 0,
					cityname:[],
					
				},
				rules: {
					account: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					password: [{required: true,min:6,max:16,trigger: 'blur',message:this.$t('QSR')}],
					repassword: [{required: true,min:6,max:16,trigger: 'blur',message:this.$t('QSR')}],
					birthday: [{required: true,trigger: 'blur',message:this.$t('QXZ')}],
					age: [{required: true,trigger: 'change',message:this.$t('QSR')}],
					skills: [{required: true,trigger: 'change',message:this.$t('QXZ')}],
					profession: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					nickname: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					realname: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					en_name: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					contact_info: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					postcode: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					location: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					address: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					captcha_code: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
					cityname: [{required: true,trigger: 'change',message:this.$t('QXZ')}],
				},
				jobForm:{
				  resume_id: 0,
				  job_time: '',
				  hope_wage: '',
				  salary_unit:'',
				  job_time_method: 0,
				  job_time_val: '',
				  hope_post: [],
				  job_type: [],
				  edu_level: '',
				  payee_name: '',
				  payee_address: '',
				  payee_account: '',
				  payee_bank: '',
				  payee_currency: '',
				  swift_code: '',
				  ext_fields: [],
				  study_exp: [
					{
					  name: '',
					  major: '',
					  start_time: '',
					  end_time: '',
					  edu_level: '',
					  experience: ''
					}
				  ],
				  job_exp: [
					{
					  name: '',
					  position: '',
					  start_time: '',
					  end_time: '',
					  salary: '',
					  content: '',
					  performance: ''
					}
				  ],
				  project_exp: [
					{
					  name: '',
					  belong: '',
					  start_time: '',
					  end_time: '',
					  //salary: '',
					  content: '',
					  performance: ''
					}
				  ]
				},
				studyRules:{
					name: [{required: true,trigger: 'blur',message:this.$t('QSR')}],
				},
				targetid:''
			};
		},
		async created() {
			this.showIndex=this.$route.query.showIndex||1;
			this.targetid=this.$route.query.targetid||'';
			
			this.getCitys();
			// if(this.lang=='jp'){
			// 	this.getCitys();
			// }else{
			// 	this.citydata=require('@/assets/js/city.json'); // 直接引入json文件;
			// }
			//this.getSkill();
			this.getCate();
			await this.getUserInfo();
			this.getJobInfo();
			this.getUnit();
			if(this.targetid){
				this.scrollToview();
			}
		},
		watch:{
			'ruleForm.birthday':{
				handler:function(nval,oval){
					if(nval){
						this.ruleForm.age=this.$util.getAge(nval);
					}else{
						this.ruleForm.age='';
					}
				}
			}
		},
		
		methods:{
			scrollToview(){
				let inter = setInterval(() => {
				  let target = document.querySelector('#'+this.targetid);
				  
				  console.log('target',target,this.targetid);
				  if (target) {
				    clearInterval(inter);
				    target.scrollIntoView(true);
				  }
				},100);
			},
			//左侧切换
			tab(n){
				this.showIndex=n;
				if(this.showIndex==1){
					this.getUserInfo();
				}else{
					this.getJobInfo();
				}
			},
			//添加自定义字段
			add_ext(){
				this.jobForm.ext_fields.push({
					name: '',
					value:''
				});
			},
			//添加学习经历
			add_study_exp(){
				this.jobForm.study_exp.push({
					name: '',
					major: '',
					start_time: '',
					end_time: '',
					edu_level: '',
					experience: ''
				});
			},
			//添加工作经历
			add_job_exp(){
				this.jobForm.job_exp.push({
					name: '',
					position: '',
					start_time: '',
					end_time: '',
					salary: '',
					content: '',
					performance: ''
				});
			},
			//添加项目经历
			add_project_exp(){
				this.jobForm.project_exp.push({
					name: '',
					belong: '',
					start_time: '',
					end_time: '',
					salary: '',
					content: '',
					performance: ''
				});
			},
			//上传头像
			async upload_avatar(res,file){
				console.log(res);
				this.ruleForm.avatar_url = res.data.Location;
			},
			
			//上传作品
			upload_photo(response, file, fileList){
				let files=[];
				if(this.ruleForm.portfolio){
					files=JSON.parse(JSON.stringify(this.ruleForm.portfolio));
				}
				
				files.push(response.data.Location);				
				this.ruleForm.portfolio=files;
				//console.log(this.ruleForm.portfolio);
			},
			remove_photo(file, fileList) {
				//console.log('fileList',this.ruleForm.photoList);
				return;
				let files=[];
				for (var i = 0; i < fileList.length; i++) {
					files.push(fileList[i].url);
				}
				this.ruleForm.portfolio=files;
				console.log(this.ruleForm.portfolio);
			},
			//获取个人信息
			async getUserInfo(){
				this.loading=true;
				let res=await this.$request.get('/api/custuser/getPersionInfo');
				this.loading=false;
				// let data=res.data;
				// let cityname=[data.province,data.city,data.area];
				// console.log(cityname);
				this.ruleForm=res.data;
				this.ruleForm.cityname=[res.data.province,res.data.city];
				//console.log(this.ruleForm);
				// let imgs=[];
				// for (var i = 0; i < this.ruleForm.portfolio.length; i++) {
				// 	imgs.push({url:this.ruleForm.portfolio[i]});
					
				// }
				//this.ruleForm.photoList=imgs;
			},
			//获取求职信息
			async getJobInfo(){
				this.loading=true;
				let res=await this.$request.get('/api/resume/getResume',{user_id:this.ruleForm.user_id});
				this.loading=false;
				let $data=res.data;
				for(let i in this.jobForm){
					//console.log(i,this.jobForm[i]);
					if($data[i]){
						this.jobForm[i]=$data[i];
					}
				}				
				console.log(this.jobForm);
			},
			async openSkill(){
				let res=await this.$refs.skills.open(this.ruleForm.skills);
				this.ruleForm.skills=res;
			},
			//获取业务技能数据
			async getSkill(){
				// this.$request.get('/api/sysdict/getAllDictTypeList');
				// this.$request.get('/api/sysdict/getAllDictDataList');
				let res=await this.$request.get('/api/setjobs/getAllSkillList');
				
				this.skillList=res.data;
			},
			//获取岗位数据
			async getCate(){
				let res=await this.$request.get('/api/setjobs/getAllCateList');
				this.cateList=res.data.records;
			},
			//获取字典数据
			async getUnit(){
				let res=await this.$request.get('/api/sysdict/getDictList');
				this.salaryUnit=res.data.salary_unit.data;
				this.jobTimeMethod=res.data.job_time_method.data;
				this.jobType=res.data.job_type.data;
				this.eduLevel=res.data.edu_level.data;
				this.openAge=res.data.open_age_method.data;
				this.openGender=res.data.open_gender_method.data;
				
			},
			//选择工作能力
			selectHope(val){
				// if(this.jobForm.hope_post.indexOf(val)==-1){
				// 	this.jobForm.hope_post.push(val);
				// }				
				// console.log(val);
				if(!val.children){
					if(this.jobForm.hope_post.indexOf(val.label)==-1){
						this.jobForm.hope_post.push(val.label);
					}
				}
				
			},
			//选择业务技能
			selectSkill(val){				
				if(this.ruleForm.skills.indexOf(val)==-1){
					this.ruleForm.skills.push(val);
				}				
				console.log(this.ruleForm.skills);
			},
			//获取省市区数据
			async getCitys(){
				let res=await this.$request.get('/api/sysdict/getJapanCityCode');
				this.citydata=res.data.records;
			},
			//选择省市区
			selectCity(val){
				console.log(val);
				this.ruleForm.province=val[0];
				this.ruleForm.city=val[1];
				if(val.length>2){
					this.ruleForm.area=val[2];
				}else{
					this.ruleForm.area='';
				}
				console.log(this.ruleForm);
				//this.$refs.ruleForm.$emit('el.form.change');
			},
			//保存基础信息
			async submitForm(){				
				let data=JSON.parse(JSON.stringify(this.ruleForm));
				delete data.cityname;
				
				console.log(data);
				this.$refs['ruleForm'].validate(async (valid) => {
				  if (valid) {
					  this.loading=true;
					let res=await this.$request.post('/api/custuser/updatePersionInfo',data);
					this.$message({
						message:res.message,
						type:'success'
					});
					this.loading=false;
					this.$request.reg({userid:this.ruleForm.user_id,nickname:this.ruleForm.nickname,faceurl:this.ruleForm.avatar_url});
					this.$router.go(-1);
					
				  } else {
					this.$message({
						message:this.$t('QTXWZXX'),
						type:'warning'
					});
				  }
				});
			},
			//保存求职信息
			async saveJob(){
				// this.$refs['studyForm'].validate(async (valid) => {
				//   if (valid) {
				// 		this.$message({
				// 			message:'验证通过',
				// 			type:'success'
				// 		});
										 
				//   } else {
				// 	this.$message({
				// 		message:this.$t('QTXWZXX'),
				// 		type:'warning'
				// 	});
				//   }
				// });
				//console.log(this.jobForm);
				//return;
				this.loading=true;
				let res=await this.$request.post('/api/resume/saveResume',this.jobForm);
				this.$message({
					message:res.message,
					type:'success'
				});
				this.loading=false;
				
			}
		}
	};
</script>

<style lang="less" scoped>
	
	.form {
		// padding: 30px;
		min-height: 100%;
		background-color: #212121;
		font-size: 14px;
		display: flex;
		justify-content: center;
		// flex-direction: column;
		// align-items: center;
		padding-top: 90px;
		padding-bottom: 90px;
		.form_left{
			display: flex;
			flex-direction: column;
			margin-right: 25px;
			.btn{
				width: 286px;
				height: 51px;
				background: #212121;
				border-radius: 7px;
				border: 1px solid #567BB6;
				display: flex;
				align-items: center;
				justify-content: center;
				color: #fff;
				box-sizing: border-box;
				margin-bottom: 20px;
			}
			.btn:hover{
				width: 286px;
				height: 51px;
				background: #567BB6;
				border-radius: 7px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: none;
				color: #fff;
				margin-bottom: 20px;
			}
			.active{
				width: 286px;
				height: 51px;
				background: #567BB6;
				border-radius: 7px;
				display: flex;
				align-items: center;
				justify-content: center;
				border: none;
				color: #fff;
				margin-bottom: 20px;
			}
			.el-button{
				margin-left: 0;
			}
		}
		.form_center {
			width: 874px;
			padding: 30px;
			background-color: #fff;
			border-radius: 20px;
			display: flex;
			flex-direction: column;
			align-items: center;
			.center_top{
				height: 40px;
				display: flex;
				align-items: center;
				width: 771px;
				border-bottom: 1px solid #567BB6;
				margin-bottom: 20px;
				position: relative;
				.top_dian{
					width: 5px;
					height: 15px;
					background: #567BB6;
					margin-right: 10px;
				}
				.top_name{
					font-size: 17px;
					font-weight: bold;
				}
			}
			.img_form{
				width: 770px;
				.avatar-uploader .el-upload {
				    border: 1px dashed #d9d9d9;
				    border-radius: 6px;
				    cursor: pointer;
				    position: relative;
				    overflow: hidden;
				  }
				  .avatar-uploader .el-upload:hover {
				    border-color: #409EFF;
				  }
				  .avatar-uploader-icon {
				    font-size: 20px;
				    color: #8c939d;
				    width: 86px;
				    height: 86px;
				    line-height: 86px;
				    text-align: center;
				  }
				  .avatar {
				    width: 86px;
				    height: 86px;
				    display: block;
				  }
			}
		}
		.top_btn{
			min-width: 120px;
			height: 29px;
			// background: #567BB6;
			border-radius: 7px;
			border: none;
			display: flex;
			align-items: center;
			justify-content: center;
			// margin-left: 30px;
			position: absolute;
			// top: 10px;
			right: 0;
		}
		.el-form{
			margin-bottom: 20px;
		}
		.picker_input{
			width: 260px;
		}
		.textarea{
			width: 630px;
		}
		.form_left{
			width: 286px;
		}
		.login_logo {
			width: 389px;
			height: 72px;
			margin-bottom: 30px;
		}

	}
	.formpart{position: relative;
		&:before{content:"";display: block;width: 771px;border-top: 1px solid #ddd;margin:0 auto 20px;}
		&:first-child:before{content:none;}
		.delbtn{position: absolute;top: 0;right: 50px;}
	}
</style>
